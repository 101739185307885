import React, { useEffect, useState } from 'react';
import axios from 'axios';  
import blog_image from '../blog1.PNG'
import DOMPurify from 'dompurify';

const Home = () => {

    
        const [posts, setPosts] = useState([]);
        const [trendingPosts, setTrendingPosts] = useState([]);
        const [categories, setCategories] = useState([]);
        const [email, setEmail] = useState('');
        const [loading, setLoading] = useState(true);
        const [selectedCategory, setSelectedCategory] = useState(null);
        const [categoryDetails, setCategoryDetails] = useState({posts: []});

        const images = [
          {id:1, image:blog_image},
        ]
      
        // Fetch latest posts
        useEffect(() => {
          const fetchPosts = async () => {
            try {
              const response = await axios.get('https://blogbackend.techieswall.com/get_latest_posts/');  
              //console.log('fetched posts: '+ response.data)
              setPosts(response.data);
              setLoading(false);
            } catch (error) {
              console.error('Error fetching latest posts:', error);
            }
          };
          fetchPosts();
        }, []);
      
        // Fetch trending posts
        useEffect(() => {
          const fetchTrendingPosts = async () => {
            try {
              const response = await axios.get('https://blogbackend.techieswall.com/trending_posts');  // Replace with your actual API endpoint
              console.log('trending posts ', response.data)
              setTrendingPosts(response.data.trending_posts);
              setLoading(false);
            } catch (error) {
              console.error('Error fetching trending posts:', error);
            }
          };
          fetchTrendingPosts();
        }, []);
      
        // Fetch categories
        // useEffect(() => {
        //   const fetchCategories = async () => {
        //     try {
        //       const response = await axios.get('http://127.0.0.1:8000/categories');  // Replace with your actual API endpoint
        //       setCategories(response.data);
        //       setLoading(false);
        //     } catch (error) {
        //       console.error('Error fetching categories:', error);
        //     }
        //   };
        //   fetchCategories();
        // }, []);


        // Fetch all categories
        useEffect(() => {
          const fetchCategories = async () => {
            try {
              const response = await axios.get('https://blogbackend.techieswall.com/categories');
              setCategories(response.data.categories || []);
              setLoading(false);
            } catch (error) {
              console.error('Error fetching categories:', error);
            }
          };
          fetchCategories();
        }, []);

        // Fetch category details when a category is selected
        const fetchCategoryDetails = async (slug) => {
          try {
            const response = await axios.get(`https://blogbackend.techieswall.com/posts_by_category/${slug}/`);
            setCategoryDetails(response.data || {posts: []});
          } catch (error) {
            console.error('Error fetching category details:', error);
          }
        };

        // Handle category selection
        const handleCategoryClick = (category) => {
          setSelectedCategory(category);
          fetchCategoryDetails(category.slug);  // Fetch details using the category slug
        };

        

        // Function to get the CSRF token from cookies
        const getCsrfToken = () => {
          const cookieValue = document.cookie.split('; ')
              .find(row => row.startsWith('csrftoken='))
              ?.split('=')[1];
          return cookieValue;
        };
      
        // Handle newsletter subscription
        const handleSubscribe = async (e) => {
          e.preventDefault();
          const csrfToken = getCsrfToken();
          console.log('token: ',csrfToken)
          console.log('email', email)
          try {
            const response = await axios.post('https://blogbackend.techieswall.com/subscribe', { email }, 
              {
              headers: {
                'X-CSRFToken': csrfToken,  // Add CSRF token to headers
            },
            });
            console.log('token: ', csrfToken)
            console.log('response', response)
            alert('Subscribed successfully!');
            setEmail('');
          } catch (error) {
            console.error('Error subscribing:', error);
            alert('Failed to subscribe. Please try again later.');
          }
        };
        
      
        if (loading) return <div>Loading...</div>;

    return(
        <>
        <div className="landing-page">
          {/* Hero Section */}  
          <div className="hero-section relative flex flex-col items-center justify-center bg-gray-100 overflow-hidden h-[75vh]">  
            {/* Background Image */}
            <img   
              src={images[0].image}   
              alt="Featured"   
              className="absolute inset-0 object-cover w-full h-full opacity-50"   
            />  

            {/* Text Content with Backdrop Blur */}
            <div className="relative z-10 flex flex-col items-center backdrop-blur-sm bg-black/30 py-8 px-6 rounded-md">  
              <h1 className="text-4xl font-bold text-center text-white">
                Discover the Latest Tech Insights
              </h1>  
              <p className="text-lg text-white mt-4">
                Stay updated with trends and news in the tech world.
              </p>  
              <a href="#subscribe">
                <button className="mt-6 bg-blue-600 text-white py-2 px-4 rounded-full transition-transform duration-300 transform hover:scale-105 hover:bg-blue-700">  
                  Subscribe Now  
                </button>
              </a>  
            </div>  
          </div>

          <div className="welcome-section bg-gray-50 py-12 px-6 md:px-12 lg:px-24 text-center">
            <h2 className="text-4xl font-semibold text-gray-800 mb-4">Techieswall Blogs</h2>
            <p className="text-lg text-gray-700 leading-relaxed">
              Welcome to Techieswall, your go-to source for insightful articles, guides, and the latest trends in 
              technology. Whether you're a tech enthusiast, a budding developer, or a seasoned professional, Techieswall 
              offers a space to explore in-depth content across software development, AI advancements, cybersecurity, and 
              more. Our mission is to bring you well-researched and practical content that can fuel your passion for tech 
              and keep you informed on cutting-edge topics. Dive into our articles, stay updated with trending posts, and 
              become a part of a growing community dedicated to learning and innovation. Happy reading!
            </p>
          </div>


          {/* Latest Posts Section */}
          
          <div className="latest-posts-section bg-white py-8">
            <h2 className="text-3xl font-semibold text-center mb-6">Latest Posts</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {posts.map((post) => (
                <a key={post.id} href={`/posts/${post.slug}/`}>
                  <div className="post-item bg-gray-50 p-4 rounded-lg shadow-lg transform transition-all duration-300 hover:bg-gray-100 hover:shadow-2xl hover:-translate-y-1">
                    {/* {post.thumbnail && <img src={post.thumbnail} alt={post.title} className="w-full h-48 object-cover rounded-t-lg" />} */}
                    <div className="post-content mt-4">
                      <h3 className="text-xl font-bold">{post.title}</h3>
                      <p className="text-gray-600 mt-2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.short_description) }}></p>
                      <p className="text-gray-500 mt-4 text-sm">Published on {new Date(post.published_date).toLocaleDateString()}</p>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
          

          {/* Trending Posts Section */}
          <div className="trending-posts-section bg-gray-100 py-8">
            <h2 className="text-3xl font-semibold text-center mb-6">Trending Posts</h2>
            <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 overflow-x-auto md:overflow-visible px-4">
              {trendingPosts.map((post) => (
                <a key={post.id} href={`/posts/${post.slug}/`}>
                  <div className="trending-post-item w-full md:w-auto bg-white p-4 rounded-lg shadow-lg transform transition-all duration-300 hover:bg-gray-50 hover:shadow-xl hover:-translate-y-1">
                    <h3 className="text-xl font-bold">{post.title}</h3>
                    <p className="text-gray-600 mt-2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content)}}></p>
                    <p className="text-gray-500 mt-4 text-sm">Read by {post.view_count} readers</p>
                  </div>
                </a>
              ))}
            </div>
          </div>

          {/* Newsletter Subscription Section */}
          <div id="subscribe" className="newsletter-section bg-blue-600 text-white py-8">
            <h2 className="text-3xl font-semibold text-center">Subscribe to Our Newsletter</h2>
            <p className="text-center mt-4">Get the latest posts delivered straight to your inbox!</p>
            <form onSubmit={handleSubscribe} className="mt-6 flex justify-center">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="p-3 rounded-l-lg w-72 text-gray-800"
                required
              />
              <button type="submit" className="bg-white text-blue-600 px-6 py-3 rounded-r-lg">Subscribe</button>
            </form>
          </div>

          {/* Categories Overview Section
          <div className="categories-overview-section py-8 bg-white">
            <h2 className="text-3xl font-semibold text-center mb-6">Explore Categories</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {categories.map((category) => (
                <div key={category.id} className="category-item bg-gray-50 p-4 rounded-lg shadow-lg text-center">
                  {category.image && <img src={category.image} alt={category.name} className="mx-auto h-24 w-24 object-cover rounded-full" />}
                  <h3 className="text-xl font-bold mt-4">{category.name}</h3>
                  <p className="text-gray-600 mt-2">{category.posts_count || 0}  Posts</p>
                </div>
              ))}
            </div>
          </div> */}

{/* <div className="categories-list">
{categories.length > 0 ? (
            categories.map((category) => (
              <div
                key={category.id}
                className="category-item bg-gray-50 p-4 rounded-lg shadow-lg text-center"
                onClick={() => handleCategoryClick(category)}
              >
                {category.image && <img src={category.image} alt={category.name} className="mx-auto h-24 w-24 object-cover rounded-full" />}
                <h3 className="text-xl font-bold mt-4">{category.name}</h3>
                <p className="text-gray-600 mt-2">{category.posts_count || 0} Posts</p>
              </div>
            ))
          ) : (
            <p>No categories available</p>
          )}
        </div> */}
      

          

        

          {/* Ad Space Section (Placeholder) */}
          <div className="ad-space-section py-8 bg-gray-200 text-center">
            <p className="text-xl text-gray-500">Ad Space</p>
        </div>
    </div>



</>
    )
}

export default Home;