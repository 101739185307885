import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../logo.png';

const Layout = ({ children }) => {

  
    const [menuOpen, setMenuOpen] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
      const fetchCategories = async () => {
          try {
              const response = await axios.get('https://blogbackend.techieswall.com/categories');
              setCategories(response.data);
          } catch (error) {
              console.error("Error fetching categories:", error);
          }
      };

      fetchCategories();
  }, []);


  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="bg-[#0A192F] text-white shadow-lg">
      <div className="container mx-auto flex justify-between items-center p-4 flex-wrap">
        
        {/* Logo and Name */}
        <div className="flex items-center">
          <img src={logo} alt="Techieswall Logo" className="h-8 w-8 mr-2" />
          <Link to="/" className="text-3xl font-bold text-[#64FFDA]">
            Techieswall
          </Link>
        </div>

        {/* Mobile Menu Toggle */}
        <button
            className="md:hidden text-2xl focus:outline-none"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            &#9776;
          </button>

        {/* Navbar Links */}
        <nav
            className={`w-full md:w-auto ${
              menuOpen ? 'block' : 'hidden'
            } md:flex space-x-6 mt-4 md:mt-0`}
          >
            <Link to="/" className="hover:text-[#64FFDA]">
              Home
            </Link>
            <Link to="/blog" className="hover:text-[#64FFDA]">
              Blog
            </Link>
            <Link to="/about" className="hover:text-[#64FFDA]">
              About
            </Link>
            <Link to="/contact" className="hover:text-[#64FFDA]">
              Contact
            </Link>
            
          </nav>
      </div>
    </header>

    <div className="w-full bg-gray-100 border-b border-gray-300 overflow-x-auto">
  <div className="flex gap-4 px-6 py-3 whitespace-nowrap justify-center">
    {categories.map((category) => (
      <a key={category.id} href={`/categories/${category.slug}/`}>
        <span
          key={category.id}
          className="text-gray-700 cursor-pointer hover:text-blue-500 transition"
        >
          {category.name}
        </span>
      </a>
    ))}
  </div>
</div>

      {/* Main Content */}
      <main className="flex-grow">{children}</main>

      {/* Footer */}
      <footer className="bg-[#0A192F] text-white p-4 mt-auto">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm">© 2024 Techieswall. All rights reserved.</p>
          <div className="space-x-4">
            <Link to="/privacy" className="hover:text-[#64FFDA] text-sm">
              Privacy Policy
            </Link>
            <Link to="/terms" className="hover:text-[#64FFDA] text-sm">
              Terms of Service
            </Link>
          </div>
          <div className="space-x-6 mt-4 md:mt-0">
            <a href="#" target="_blank" rel="noopener noreferrer" className="hover:text-[#64FFDA]">
              Twitter
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer" className="hover:text-[#64FFDA]">
              LinkedIn
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer" className="hover:text-[#64FFDA]">
              GitHub
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
