import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';


const Contact = () => {

    return(
        <div className="bg-gray-100 p-8 rounded-lg shadow-lg max-w-md mx-auto text-center">
      <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>

      {/* Contact Details */}
      <div className="text-gray-700 mb-6">
        <p className="mb-2">
          <strong>Email:</strong> admin@techieswall.com
        </p>
        <p className="mb-2">
          <strong>Phone:</strong> +254717836655
        </p>
        <p>
          <strong>Address:</strong> Nairobi, Kenya
        </p>
      </div>

      {/* Social Media Links */}
      <div className="flex justify-center space-x-6 text-gray-600 mt-6">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600">
          <FaFacebookF size={24} />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400">
          <FaTwitter size={24} />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-800">
          <FaLinkedinIn size={24} />
        </a>
      </div>
    </div>
    )
}

export default Contact;