import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { use } from 'react';
import DOMPurify from 'dompurify';

const CreatePost = () => {
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [content, setContent] = useState('');
    const [status, setStatus] = useState('draft'); // default status
    const [tags, setTags] = useState('');
    const [categories, setCategories] = useState([]); // State to hold categories
    const [selectedCategory, setSelectedCategory] = useState(''); // State for selected category
    const [isEditorReady, setIsEditorReady] = useState(false);

    useEffect(() => {
        // Fetch categories from the endpoint
        fetch('https://blogbackend.techieswall.com/categories')
            .then((response) => response.json())
            .then((data) => {
                setCategories(data); // Assuming the response contains the categories in an array
            })
            .catch((error) => console.error('Error fetching categories:', error));
    }, []);




    // Custom upload adapter plugin for CKEditor
    function MyUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new MyUploadAdapter(loader);
        };
    }

    // Define the upload adapter class
    class MyUploadAdapter {
        constructor(loader) {
            this.loader = loader;
        }

        upload() {
            return this.loader.file
                .then((file) =>
                    new Promise((resolve, reject) => {
                        const data = new FormData();
                        data.append('image', file);

                        fetch('https://blogbackend.techieswall.com/upload_image/', { // Replace with your actual upload URL
                            method: 'POST',
                            body: data,
                            headers: {
                                'X-CSRFToken': getCookie('csrftoken'),
                            },
                            credentials: 'include'
                        })
                            .then((response) => response.json())
                            .then((result) => {
                                resolve({
                                    default: result.url // URL to the uploaded image
                                });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                );
        }

        abort() {
            // Optional: implement abort functionality if needed.
        }
    }

    
    const handleSubmit = async (e) => {
        e.preventDefault();

        //form-encode the data
        // const formData = new URLSearchParams();
        // formData.append('title', title);
        // formData.append('slug', slug);
        // formData.append('content', content);
        // formData.append('status', status);
        // formData.append('category', selectedCategory);
        // formData.append('tags', tags.split(',').map(tag => tag.trim()));
        const formData = {
            title: title,
            slug: slug,
            content: content,
            status: status,
            category: selectedCategory,
            tags: tags.split(',').map(tag => tag.trim())
        }

        try {
            const accessToken = localStorage.getItem('access_token');
            const response = await axios.post('https://blogbackend.techieswall.com/posts/create/', formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'), // CSRF token for Django
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (response.data.success) {
                alert('Post created successfully!');
                // Reset the form
                setTitle('');
                setSlug('');
                setContent('');
                setStatus('draft');
                setTags('');
            }
        } catch (error) {
            alert('Error creating post: ' + error.response.data.message);
        }
    };

    // Function to get the CSRF token from cookies
    const getCookie = (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Check if the cookie name matches
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    };

    useEffect(() => {
        setIsEditorReady(true)
    }, []);

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        const sanitizedData = DOMPurify.sanitize(data);
        setContent(sanitizedData);
    }

    return (
        <div className="max-w-xl mx-auto p-6 border border-gray-300 rounded-lg shadow-lg bg-white">
        <h1 className="text-2xl font-bold mb-4 text-center">Create a New Post</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title:</label>
                <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
                />
            </div>
            <div>
                <label htmlFor="slug" className="block text-sm font-medium text-gray-700">Slug:</label>
                <input
                    type="text"
                    id="slug"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    required
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="category" className="block text-gray-700 font-medium mb-2">
                    Category:
                </label>
                <select
                    id="category"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    required
                    className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                    <option value="">Select a category</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
            {/* <p className="text-gray-600 text-sm mb-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content.substring(0, 100))}}></p> */}
                <label className="block text-sm font-medium text-gray-700">Content:</label>
                <div className="mt-1" id='content' name='content'>
                    
                    {isEditorReady && (
                        <CKEditor
                            editor={ClassicEditor}
                            data={DOMPurify.sanitize(content)}
                            config={{
                                extraPlugins: [MyUploadAdapterPlugin], // Add custom upload adapter
                                // Set the height of the editor here
                                height: '800px', 
                            }}
                            onChange={handleEditorChange}
                        />
                    )}
                </div>
            </div>
            <div>
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status:</label>
                <select
                    id="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
                >
                    <option value="draft">Draft</option>
                    <option value="published">Published</option>
                </select>
            </div>
            <div>
                <label htmlFor="tags" className="block text-sm font-medium text-gray-700">Tags (comma-separated):</label>
                <input
                    type="text"
                    id="tags"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
                />
            </div>
            <button type="submit" className="w-full bg-blue-600 text-white font-semibold py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300">
                Create Post
            </button>
        </form>
    </div>
    );
};

export default CreatePost;
