import { useParams, Link } from "react-router-dom"
import axios from "axios";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";


function CategoryDetails () {
    const {slug} = useParams();
    const [categoryPosts, setCategoryPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 10;
    const [categoryName, setCategoryName] = useState('');
    
    useEffect(() =>{
        const fetchDetails = async () =>{
            try {
                // Fetch category name based on slug
                const categoryResponse = await axios.get(`https://blogbackend.techieswall.com/posts_by_category/${slug}/`);
                setCategoryName(categoryResponse.data.category);


                const response = await axios.get(`https://blogbackend.techieswall.com/posts_by_category/${slug}/`);
                //const data = await response.json()
                setCategoryPosts(Array.isArray(response.data) ? response.data : response.data.posts || "No posts for this category")
                
            } catch (error) {
                console.log('An error occurred while fetching the data', error)
            }finally {
                setLoading(false)
            }
            
        };
        fetchDetails();
    }, [slug])

    if (loading) return <p>Loading...</p>

    if (categoryPosts.length === 0) return <p>No posts available in this category yet.</p>;

    // Pagination logic
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = categoryPosts.slice(indexOfFirstPost, indexOfLastPost);
    const totalPages = Math.ceil(categoryPosts.length / postsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };


    return (
        <>
            <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center">Category: {categoryName}</h1>

            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {currentPosts.map((post) => (
                    <div key={post.id} className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow">
                        <h2 className="text-2xl font-semibold mb-2">{post.title}</h2>
                        <p className="text-gray-600 text-sm mb-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content.substring(0, 100))}}></p>
                        <p className="text-gray-500 text-xs mb-1">{new Date(post.date).toLocaleDateString()}</p>
                        <p className="text-gray-700 font-medium text-sm">Author: {post.author.username}</p>
                        <Link 
                        to={`/posts/${post.slug}`}
                        className="mt-4 inline-block text-blue-500 hover:underline">
                            Read more
                        </Link>
                    </div>
                ))}
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-center items-center space-x-4 mt-8">
                <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-gray-200 text-gray-600 rounded-lg hover:bg-gray-300 disabled:opacity-50"
                >
                    Previous
                </button>
                <span className="text-gray-700">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 bg-gray-200 text-gray-600 rounded-lg hover:bg-gray-300 disabled:opacity-50"
                >
                    Next
                </button>
            </div>
        </div>
        </>
    )
}

export default CategoryDetails;