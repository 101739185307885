
import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import DOMPurify from 'dompurify';

function PostDetail() {
    const {slug} = useParams();
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [replies, setReplies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [comment, setComment] = useState('');
    const [replyContent, setReplyContent] = useState('');
    const [replyingTo, setReplyingTo] = useState(null);


//console.log('this annoying post', post.id)

    useEffect(() => {
        const fetchPost = async () =>{
            try{
                const response = await fetch(`https://blogbackend.techieswall.com/posts/${slug}`);
                const data = await response.json();
                setPost(data.post);
                setComments(data.comments);
                setReplies(data.replies);
                setLoading(false)
               
            } catch (error){
                console.log('error fetching post data', error)
            }
        };
        fetchPost(); 
    }, [slug])

    // Function to get the CSRF token from cookies
    function getCSRFToken() {
        let csrfToken = null;
        if (document.cookie) {
            document.cookie.split(';').forEach(cookie => {
                const [name, value] = cookie.trim().split('=');
                if (name === 'csrftoken') {
                    csrfToken = decodeURIComponent(value);
                }
            });
        }
        return csrfToken;
    }

    const handleCommentSubmit = async (e) =>{
        e.preventDefault();
        if (!comment || !post){
            console.log('not available')
            return;
        }
        
        console.log('selected post', post)
        console.log('selected post', post[0].id)
        console.log('comment', comment)
        const csrfToken = getCSRFToken();
        try{
            console.log('another comment:', comment)
            const response = await fetch(`https://blogbackend.techieswall.com/add_comment/${post[0].id}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
               
                body: JSON.stringify({content: comment}),
            });
            const data = await response.json();
            console.log('API response', data);
            if  (data.success){
                setComments([...comments, data.comment])  //adding new comment to the state
                setComment('') //clearing the comment field
            } else{
                console.log(data.message)
            }
        } catch (error) {
            console.log('error posting comment', error);
        }
    }

    const handleReplySubmit = async (e, parentId) =>{
        e.preventDefault();
        if (!replyContent){
            return;
        }
        const csrfToken = getCSRFToken();
        try{
            const response = await fetch(`https://blogbackend.techieswall.com/add_comment/${post[0].id}/${parentId}/`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({content: replyContent})
            });
            const data = await response.json();
            if (data.success){
                setComments((prevComments)=> {
                    return prevComments.map(comment =>{
                        if (comment.id === parentId) {
                            return {...comment, replies: [...PostDetail(comment.replies || []), data.comment]};
                        }
                        return comment;
                    })
                })
                
                setReplyContent('');
                setReplyingTo(null)
            } else{
                console.log(data.message);
            }
        } catch (error) {
            console.log('An error occurred', error)
        }

    }

    const toggleReplyForm = (commentId) => {
        setReplyingTo(replyingTo === commentId ? null : commentId);
    };

   // if (loading) return <p>Loading...</p>


    return(
        <>
             <div className="max-w-3xl mx-auto p-8 bg-white rounded-lg shadow-md">
    {post && post.map((this_post) =>(
        <div key={this_post.id}>
            <h1 className="text-5xl font-bold mb-2 text-gray-800">{this_post.title}</h1>
            <p className="text-sm text-gray-500 mb-4">Published on: {new Date(this_post.date).toLocaleDateString()}</p>
            <p className="text-md font-semibold text-gray-600 mb-6">Written by: {this_post.author.username}</p>
            <p className="text-gray-700 leading-relaxed" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this_post.content)}}></p>
        </div>
    ))}

    {/* Comment Form */}
    <div className="mt-8 border-t border-gray-200 pt-6">
        <h3 className="text-lg font-semibold mb-2 text-gray-700">Add a Comment</h3>
        <form onSubmit={handleCommentSubmit} className="mb-6">
            <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Write a comment..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                rows="4"
            />
            <button
                type="submit"
                className="mt-3 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
            >
                Submit Comment
            </button>
        </form>
    </div>

    {/* Comments Section */}
    <div className="mt-6">
        <h3 className="text-lg font-semibold mb-4 text-gray-700">Comments</h3>
        {comments.length > 0 ? (
            comments.map((comment) => (
                <div key={comment.id} className="border-b border-gray-200 py-4">
                    <div className="flex items-center justify-between">
                        <span className="font-bold text-gray-700">{comment.user?.username || comment.anonymous_user || "Unknown"}</span>
                        <span className="text-xs text-gray-500">{new Date(comment.date).toLocaleDateString()}</span>
                    </div>
                    <p className="text-gray-600 mt-2">{comment.content}</p>

                    {/* Reply Button */}
                    <button
                        onClick={() => toggleReplyForm(comment.id)}
                        className="text-sm text-blue-500 hover:underline mt-2"
                    >
                        {replyingTo === comment.id ? 'Hide Reply' : 'Reply'}
                    </button>

                    {/* Reply Form */}
                    {replyingTo === comment.id && (
                        <div className="mt-2 ml-6">
                            <form onSubmit={(e) => handleReplySubmit(e, comment.id)}>
                                <textarea
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    placeholder="Write your reply..."
                                    value={replyContent}
                                    onChange={(e) => setReplyContent(e.target.value)}
                                    rows="3"
                                />
                                <button
                                    type="submit"
                                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
                                >
                                    Submit Reply
                                </button>
                            </form>
                        </div>
                    )}

                    {/* Display Replies */}
                    {comment.replies && (
                        <div className="mt-4 ml-6">
                            {comment.replies.map((reply) => (
                                <div key={reply.id} className="border-l-2 border-gray-300 pl-4 py-2">
                                    <div className="flex items-center justify-between">
                                        <span className="font-bold text-gray-700">{reply.user?.username || reply.anonymous_user || "Unknown"}</span>
                                        <span className="text-xs text-gray-500">{new Date(reply.date).toLocaleDateString()}</span>
                                    </div>
                                    <p className="text-gray-600 mt-1">{reply.content}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))
        ) : (
            <p className="text-gray-500">No comments yet. Be the first to comment!</p>
        )}
    </div>
</div>
        </>
    )

}

export default PostDetail;