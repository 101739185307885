import React, {useState, useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";


const Blogs = () => {

    const [posts, setPost] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState({
        number: 1,
        has_next: false,
        has_prev: false,
        total_pages: 1,
        total_posts: 0
    })

    
        const fetchPosts = async () =>{
            try{
                const response = await axios.get('https://blogbackend.techieswall.com/posts/');
                setPost(response.data.posts);
                setPageData(response.data.page);
                setLoading(false);
            }catch (error){
                console.log("error fetching posts", error)
            }
        } 
        
   

    useEffect(() => {
        fetchPosts(pageData.number);
    }, [pageData.number]);

    const onPageChange = (newPageNumber) => {
        setPageData((prev) => ({...prev, number: newPageNumber}));
    }

    if (loading) return <div>Loading...</div>;

    return(
        <>
        <div className="max-w-4xl mx-auto px-4 py-8">
            {/* Title and Storyline */}
            <header className="text-center mb-8">
                <h1 className="text-4xl font-bold text-gray-800">Blogs</h1>
                <p className="text-lg text-gray-600 mt-4">
                Dive into our collection of insightful articles and updates. Our blogs are crafted to keep you informed, inspire new ideas, and help you stay ahead. Discover stories, tips, and news that truly matter.
                </p>
            </header>

            {/* Blog Post List */}
            <div className="grid gap-6 md:grid-cols-2">
                {posts.map((post) => (
                <div key={post.id} className="border border-gray-200 rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow">
                    <h3 className="text-xl font-semibold text-gray-800">{post.title}</h3>
                    <p className="text-gray-600 mt-2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.brief) }}></p>
                    <p className="text-sm text-gray-500 mt-4">
                    Published on {new Date(post.date).toLocaleDateString()}
                    </p>
                    <Link 
                    to={`/posts/${post.slug}`}
                    className="mt-4 inline-block text-blue-500 hover:underline">
                        Read more
                    </Link>
                    {/* <button className="mt-4 inline-block text-blue-500 hover:underline">Read More</button> */}
                </div>
                ))}
            </div>

            {/* Pagination Controls */}
      <div className="flex justify-center items-center mt-10 space-x-4">
        <button
          onClick={() => onPageChange(pageData.number - 1)}
          disabled={!pageData.has_prev}
          className={`px-4 py-2 rounded-md ${pageData.has_prev ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {pageData.number} of {pageData.total_pages}
        </span>
        <button
          onClick={() => onPageChange(pageData.number + 1)}
          disabled={!pageData.has_next}
          className={`px-4 py-2 rounded-md ${pageData.has_next ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
        >
          Next
        </button>
      </div>
      </div>
        </>
        
    );
}

export default Blogs;