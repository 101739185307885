import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function UserLogin() {
    axios.defaults.withCredentials = true;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();

        //form-encode the data
        const formData = new URLSearchParams();
        formData.append('email', email);
        formData.append('password', password);
        try {
            const response = await axios.post('https://blogbackend.techieswall.com/user-login/', formData, {
                withCredentials: true,
            });

            if (response.data.success) {
                // Login successful
                setMessage(`Welcome, ${response.data.user.first_name} ${response.data.user.last_name}`);
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('refresh_token', response.data.refresh_token);
                alert(response.data.message)
                navigate('/add_post/');
            } else {
                setMessage(response.data.message);
            }
        } catch (error) {
            // Handle error, such as network issues or 401 Unauthorized
            setMessage('Login failed: Invalid credentials or server error.');
        }
    };

    return (
        <div class="flex items-center justify-center min-h-screen bg-gray-100">
            <div class="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
                <h2 class="text-2xl font-semibold text-center text-gray-700 mb-6">Login</h2>
                <form onSubmit={handleLogin} class="space-y-6">
                    <div>
                        <label class="block text-gray-600 mb-1">Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                        />
                    </div>
                    <div>
                        <label class="block text-gray-600 mb-1">Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                        />
                    </div>
                    <button
                        type="submit"
                        class="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
                    >
                        Login
                    </button>
                </form>
                {message && <p class="text-center text-red-500 mt-4">{message}</p>}
            </div>
        </div>
    );
}

export default UserLogin;
