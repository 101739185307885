import axios from "axios";

const API_URL = "http://127.0.0.1:8000/";

const register = (email, password, first_name, last_name) => {
  return axios.post(API_URL + "signup/", {
    email,
    password,
    first_name,
    last_name,
  });
};

const login = (email, password) => {
  return axios
    .post(API_URL + "login/", { email, password })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  return axios.post(API_URL + "logout/");
};

export default { register, login, logout };
