import logo from './logo.png';
import './App.css';

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import Blogs from './components/Blogs';
import About from './components/About';
import Contact from './components/Contact';
import AdminRegister from './components/AdminRegister';
import AdminLogin from './components/AdminLogin';
import AdminEdits from './components/AdminEdits';
import UserLogin from './components/UserLogin';
import PostDetail from './components/PostDetail';
import CategoryDetails from './components/CategoryDetails';
import CreatePost from './components/CreatePost';
import RoleBasedContent from './components/RoleBasedContent';

function App() {
  return (
    <>
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/admin-register" element={<AdminRegister />} />
          <Route path="/login" element={<AdminLogin />} />
          <Route path="/admin-edits" element={<AdminEdits />} />
          {/* <Route path="/login" element={<UserLogin />} /> */}
          <Route path="/posts/:slug" element={<PostDetail />} />
          <Route path='/categories/:slug' element={<CategoryDetails />} />
          <Route path='/add_post/' element={<CreatePost />} />
        </Routes>
      </Layout>
    </Router>
    
</>
  );
}


export default App;
