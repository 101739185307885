

const About = () => {

    return(
        <div className="about-section bg-white py-12 px-6 md:px-12 lg:px-24 text-center">
  <h2 className="text-4xl font-semibold text-gray-800 mb-6">About Us</h2>
  <p className="text-lg text-gray-700 leading-relaxed mb-6">
    Techieswall is dedicated to bringing you the latest insights, practical guides, and thought-provoking discussions 
    from the tech world. Our content covers a wide range of topics—from software development and artificial 
    intelligence to cybersecurity and emerging trends—crafted to meet the needs of tech enthusiasts, budding 
    developers, and seasoned professionals alike. We aim to provide a welcoming platform where readers can dive deep
    into technical knowledge, stay informed on industry developments, and gain inspiration for their own tech 
    journeys. As we grow, we’re excited to expand our offerings to include interactive tutorials, community-driven 
    forums, and exclusive content that fosters learning and innovation. Join us as we continue building a community 
    of curious, forward-thinking minds committed to shaping the future of technology.
  </p>

  <h3 className="text-2xl font-bold text-gray-800 mt-8 mb-4">Our Mission</h3>
  <p className="text-lg text-gray-700 leading-relaxed">
    At Techieswall, our mission is to empower and inspire tech enthusiasts, developers, and professionals through 
    insightful, high-quality content that bridges the gap between innovation and practical knowledge. We strive 
    to be a trusted source for the latest in technology, offering a platform for exploration, learning, and growth 
    across software development, AI, cybersecurity, and beyond. Through dedication to depth, accuracy, and 
    community, we aim to foster a space where curiosity thrives and knowledge becomes a tool for impactful change 
    in the digital world.
  </p>
</div>

        
    )
}
export default About;