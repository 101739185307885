import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // For navigation after profile update

const EditProfile = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    current_password: '',
    new_password: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fetch user profile data on component mount
  useEffect(() => {
    axios
      .get('http://127.0.0.1:8000/edit/', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }) // Adjust API URL and auth header
      .then((response) => {
        setProfileData({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          email: response.data.email,
        });
      })
      .catch((error) => {
        console.error("Error fetching profile data", error);
      });
  }, []);

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put('http://127.0.0.1:8000/edit/', profileData, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }) // Adjust API URL
      .then((response) => {
        setSuccess("Profile updated successfully");
        setError('');
        // Redirect or refresh the page to show updated profile
        navigate('/profile');
      })
      .catch((error) => {
        setError("Failed to update profile");
        console.error("Error updating profile", error);
      });
  };

  return (
    <div className="max-w-lg mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Edit Profile</h2>
      {success && <div className="bg-green-100 text-green-700 p-2 rounded mb-4">{success}</div>}
      {error && <div className="bg-red-100 text-red-700 p-2 rounded mb-4">{error}</div>}

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2">First Name</label>
          <input
            type="text"
            name="first_name"
            value={profileData.first_name}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Last Name</label>
          <input
            type="text"
            name="last_name"
            value={profileData.last_name}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Email</label>
          <input
            type="email"
            name="email"
            value={profileData.email}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Current Password (required to update password)</label>
          <input
            type="password"
            name="current_password"
            value={profileData.current_password}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">New Password (optional)</label>
          <input
            type="password"
            name="new_password"
            value={profileData.new_password}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <button type="submit" className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditProfile;
